import React from "react";

import './styles.scss';

export default function LoadingIndicator() {
    return (
        <div className="loading-container">
            <div className="loading-indicator"></div>
        </div>
    );
}
