import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Copyright from "../Copyright";

import logo from "../../assets/logo80.png";

import "./styles.scss";

export default function Footer() {
    return (
        <Container maxWidth="xl" className="footer-container">
            <div className="socials-container">
                <Link
                    href="https://www.linkedin.com/company/toggles"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                    title="Connect with Toggles on LinkedIn"
                >
                    <LinkedInIcon className="social-icon" />
                </Link>
                <Link
                    href="https://x.com/togglesforemail"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                    title="Follow Toggles on X for Updates"
                >
                    <XIcon className="social-icon" />
                </Link>
                <Link
                    href="https://www.youtube.com/@TogglesForEmail"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                    title="Watch Toggles Videos on YouTube"
                >
                    <YouTubeIcon className="social-icon" />
                </Link>
            </div>
            <div className="navigation-container">
                <RouterLink
                    to="/"
                    title="Visit Toggles for Outlook - Email Productivity Tool"
                >
                    <img src={logo} className="nav-logo" alt="Toggles Logo" />
                </RouterLink>
                <div className="navigation">
                    <Grid container spacing={3} className="container">
                        <Grid xs={12} sm={4} className="nav-column">
                            <Typography
                                variant="h6"
                                className="column-label"
                                gutterBottom
                            >
                                Product
                            </Typography>
                            <Link
                                component={RouterLink}
                                to="/"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Explore Toggles email productivity tool features"
                            >
                                Features
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/teams"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Explore Toggles for Teams - Boost Email Productivity"
                            >
                                Teams
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/pricing"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Compare Pricing Plans for Toggles for Outlook"
                            >
                                Pricing
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/request-demo"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Request a Demo of Toggles for Outlook"
                            >
                                Request Demo
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={4} className="nav-column">
                            <Typography
                                variant="h6"
                                className="column-label"
                                gutterBottom
                            >
                                Use Cases
                            </Typography>
                            <Link
                                component={RouterLink}
                                to="/solutions/finance"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Finance Teams - Efficient Email Automation with Toggles"
                            >
                                Finance
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/solutions/sales"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Sales Teams - Streamline Emails with Toggles"
                            >
                                Sales
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/solutions/human-resources"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="HR Teams - Simplify Email Workflows with Toggles"
                            >
                                Human Resources
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/solutions/customer-success"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Customer Success Teams - Boost Client Communicatio"
                            >
                                Customer Success
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={4} className="nav-column">
                            <Typography
                                variant="h6"
                                className="column-label"
                                gutterBottom
                            >
                                Resources
                            </Typography>
                            <Link
                                component={RouterLink}
                                to="/support"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Toggles Support - Help and Resources"
                            >
                                Support
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/blog"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Toggles Blog"
                            >
                                Blog
                            </Link>
                            <Link
                                href="mailto:sales@gettoggles.com"
                                target="_blank"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Contact the Toggles Sales Team by email"
                                rel="noopener noreferrer"
                            >
                                Contact Sales
                            </Link>
                            <Link
                                component={RouterLink}
                                to="/productivity-calculator"
                                className="nav-item"
                                underline="none"
                                color="inherit"
                                title="Calculate Email Productivity with Toggles"
                            >
                                ROI Calculator
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Copyright />
        </Container>
    );
}
