import React, { useEffect, Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import Layout from './Layout';
import LoadingIndicator from "./components/LoadingIndicator";

// Initialize React Ga with your tracking ID
ReactGA.initialize("G-E90JF20ML5", {
    gaOptions: {
        debug_mode: window?.location?.host !== 'www.gettoggles.com',
    },
    gtagOptions: {
        debug_mode: window?.location?.host !== 'www.gettoggles.com',
    },
});

// Lazy load all the pages
const Home = lazy(() => import("./pages/Home"));
const BusinessPro = lazy(() => import("./pages/BusinessPro"));
const Pricing = lazy(() => import("./pages/Pricing"));
const Support = lazy(() => import("./pages/Support"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const NotFound = lazy(() => import("./pages/NotFound"));
const RequestDemo = lazy(() => import("./pages/RequestDemo"));
const HumanResourcesSolution = lazy(() => import("./pages/solutions/HumanResources"));
const FinancialServicesSolution = lazy(() => import("./pages/solutions/FinancialServices"));
const CustomerSuccessSolution = lazy(() => import("./pages/solutions/CustomerSuccess"));
// const HumanResourcesTemplates = lazy(() => import("./pages/resources/templates/HumanResources"));
const SalesSolution = lazy(() => import("./pages/solutions/Sales"));
const ProductivityCalculator = lazy(() => import("./pages/ProductivityCalculator"));
const ArticleList = lazy(() => import("./pages/articles/ArticleList"));
const ArticlePage = lazy(() => import("./pages/articles/components/ArticlePage"));

export default function App() {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search,
        });
    }, [location]);

    return (
        <HelmetProvider>
            <div className="app-container">
                <Suspense fallback={<LoadingIndicator />}>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path="teams" element={<BusinessPro />} />
                            <Route path="pricing" element={<Pricing />} />
                            <Route path="support" element={<Support />} />
                            <Route
                                path="request-demo"
                                element={<RequestDemo />}
                            />
                            <Route
                                path="productivity-calculator"
                                element={<ProductivityCalculator />}
                            />
                            <Route path="privacy" element={<Privacy />} />
                            <Route path="terms" element={<Terms />} />

                            <Route path="solutions/">
                                <Route
                                    path="human-resources"
                                    element={<HumanResourcesSolution />}
                                />
                                <Route
                                    path="finance"
                                    element={<FinancialServicesSolution />}
                                />
                                <Route
                                    path="customer-success"
                                    element={<CustomerSuccessSolution />}
                                />
                                <Route
                                    path="sales"
                                    element={<SalesSolution />}
                                />
                            </Route>

                            <Route path="blog/">
                                <Route index element={<ArticleList />} />
                                <Route path=":slug" element={<ArticlePage />} />
                            </Route>

                            {/* <Route path="resources/">
                                <Route path="templates/">
                                    <Route
                                        path="human-resources"
                                        element={<HumanResourcesTemplates />}
                                    />
                                </Route>
                            </Route> */}

                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </Suspense>
            </div>
        </HelmetProvider>
    );
}
