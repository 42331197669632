import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module "@mui/material" {
    interface Color {
        dark: string;
        main: string;
        light: string;
    }
}

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: "'Noto Sans', sans-serif",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                font-family: 'Noto Sans';
                font-style: normal;
                font-display: swap;
                font-weight: 400;
                font-display: swap;
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `,
        },
    },
    palette: {
        primary: {
            main: "#525CEB",
        },
        secondary: {
            main: "#19857b",
        },
        grey: {
            light: "#BBBBBB",
            main: "#3D3B40",
            dark: "#222222",
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;
